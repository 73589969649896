@import url(~antd/dist/antd.css);

#root {
  width: 100%;
  position: relative;
}

.fill {
  width: 100%;
  height: 100%;
  position: relative;
}

.header {
  color: white;
  width: 100%;
  height: 0.40104rem;
  line-height: 0.40104rem;
  position: absolute;
  top: 0;
  z-index: 1;
  border-bottom: 0.00521rem solid rgba(255,255,255,0.68);
  display: flex;
  justify-content: space-between;
  padding: 0 1.875rem;
}

.header .left {
  font-size: 0.14063rem;
  font-weight: bold;
}

.header .nav {
  font-size: 0.10417rem;
}

.header .nav .nav-item {
  padding: 0.13021rem 0.20833rem;
  cursor: pointer;
  position: relative;
}

.header .nav .nav-item.current {
  color: #0499FF;
}

.header .nav .nav-item.current:nth-child(2) {
  color: #333;
}

.header .nav .current:nth-child(2)::after {
  background: #333;
}

.header .nav .current::after {
  content: '';
  width: 0.18229rem;
  height: 0.02083rem;
  background: #0499FF;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -0.09375rem;
  border-radius: 0.00521rem;
}

.header .right .btn {
  width: 0.76042rem;
  height: 0.23958rem;
  font-size: 0.10417rem;
  text-align: center;
  line-height: 0.23958rem;
  background: #0499FF;
  border-radius: 0.02083rem;
  display: inline-block;
}

.header .right .btn.black {
  background: #333;
  color: #fff;
}

.footer {
  color: #fff;
  height: 2.08333rem;
  background: #545454;
  padding: 0.5rem 1.61458rem 0 1.625rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer .warp {
  display: flex;
  justify-content: space-between;
}

.footer .warp .company {
  font-size: 0.10417rem;
}

.footer .warp .company .name {
  font-size: 0.20833rem;
  font-weight: bold;
  margin-bottom: 0.20833rem;
}

.footer .warp .wx-code {
  text-align: center;
}

.footer .warp .wx-code img {
  width: 1.09896rem;
  display: inline-block;
  margin-bottom: 0.10417rem;
}

.footer .record {
  margin: 0.10417rem auto;
  font-size: 0.08333rem;
}

.footer a:link,
.footer a:visited {
  text-decoration: none;
  color: #999;
}

.footer a:hover,
.footer a:active {
  cursor: pointer;
}

.font-size-big {
  font-size: 0.3125rem !important;
}

.font-size-normal {
  font-size: 0.20833rem !important;
}

.font-size-small {
  font-size: 0.10417rem !important;
}

.font-medium {
  font-weight: bold !important;
}

.font-color-gray {
  color: #666;
}

.font-color-black {
  color: #333;
}

.font-color-white {
  color: #fff;
}

.text-align-left {
  text-align: left !important;
}

.text-align-right {
  text-align: right !important;
}

.text-align-center {
  text-align: center !important;
}

.bg-cyan {
  background: #506D99 !important;
}

.bg-thin-cyan {
  background: #6F9AC3 !important;
}

.bg-indigo {
  background: #29D9BB !important;
}

.margin-0 {
  margin: 0 !important;
}

.no-border {
  border: none !important;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #999999;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #999999;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #999999;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #999999;
}

img {
  width: 100%;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}