.app {
  text-align: center;
}

.app .app-header {
  width: 100%;
  height: 4.16667rem;
  position: relative;
}

.app .app-header .dots {
  width: 100%;
  bottom: 0.3125rem;
  position: absolute;
}

.app .app-header .dots .dot {
  display: inline-block;
  margin-right: 0.20833rem;
  width: 0.10417rem;
  height: 0.10417rem;
  background: #fff0;
  border-radius: 50%;
  border: 0.00521rem solid rgba(255,255,255,0.5);
  cursor: pointer;
}

.app .app-header .dots .dot.active {
  background: #0499ff;
  border: 0.00521rem solid #0499ff;
}

.app .notice {
  height: 0.625rem;
  background: #FFF;
  padding: 0 1.89063rem;
  display: flex;
  font-size: 0.13542rem;
  align-items: center;
  justify-content: space-between;
}

.app .notice .notice-content {
  display: flex;
  align-items: center;
}

.app .notice .notice-content .notice-icon {
  width: 0.21875rem;
  margin-right: 0.13542rem;
}

.app .section {
  height: 2.53125rem;
  background: #F5F5F5;
  position: relative;
}

.app .section .section-title {
  padding-top: 0.41667rem;
  font-size: 0.17708rem;
  color: #3B3B3B;
  font-weight: bold;
  display: inline-block;
}

.app .section .section-title .postfix {
  position: relative;
  line-height: 0.25rem;
}

.app .section .section-title .postfix::before {
  content: '';
  width: 0.41667rem;
  height: 0.04167rem;
  background: #0499FF;
  border-radius: 0.00521rem;
  position: absolute;
  left: 50%;
  bottom: -0.10417rem;
  margin-left: -0.20833rem;
}

.app .section .section-content {
  padding: 0.35417rem 1.81771rem 0 1.82292rem;
  display: flex;
  font-size: 0.13542rem;
  justify-content: space-between;
}

.app .section .section-content .section-item {
  width: 1.74479rem;
  height: 0.88542rem;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
}

.app .section .section-content .section-item .item-icon {
  width: 0.4375rem;
  height: 0.4375rem;
  margin-right: 0.22917rem;
}

.app .section .section-content .section-item.active {
  color: #FFF;
  background: #0499FF;
}

.app .section .bg-car {
  width: 3.02083rem;
  height: 1.23438rem;
  position: absolute;
  top: 0.375rem;
  right: 0.1875rem;
}

.app .section2 {
  height: 4.00521rem;
}

.app .section2 .section-title {
  color: #333333;
}

.app .section2 .section-title .postfix::before {
  bottom: -0.14583rem;
}

.app .section2 .section-scheme {
  height: 2.90104rem;
  margin: 0.125rem 0.9375rem 0.3125rem 0.9375rem;
  border: 0.00521rem solid rgba(153,153,153,0.2);
  display: flex;
}

.app .section2 .section-scheme .mockup {
  width: 3rem;
  height: 2.48438rem;
  margin: 0.20833rem 1.03646rem 0 0.9375rem;
}

.app .section2 .section-scheme .mockup img {
  width: 100%;
}

.app .section2 .section-scheme .flow {
  padding-top: 0.63542rem;
  border-left: 0.00521rem solid rgba(153,153,153,0.2);
}

.app .section2 .section-scheme .flow .flow-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app .section2 .section-scheme .flow .flow-items .item {
  font-size: 0.13542rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.22396rem;
  position: relative;
}

.app .section2 .section-scheme .flow .flow-items .item .flow-icon {
  width: 0.15625rem;
  height: 0.15625rem;
  margin-right: 0.0625rem;
  margin-left: 0.20833rem;
}

.app .section2 .section-scheme .flow .flow-items .item .prefix {
  width: 0.04167rem;
  height: 0.15625rem;
  display: inline-block;
  background: #0499FF;
}

.app .section2 .section-scheme .flow .flow-items .item.active {
  color: #0499FF;
}

.app .aside {
  height: 2.91667rem;
  display: flex;
}

.app .aside .aside-item {
  width: 50%;
  height: 100%;
}

.app .aside .aside-left {
  padding: 0.4375rem 0.4375rem 0 1.86979rem;
  background: #FFF;
  text-align: left;
}

.app .aside .aside-left .aside-title {
  color: #333;
  font-weight: bold;
  font-size: 0.17708rem;
  margin-bottom: 0.47917rem;
  position: relative;
}

.app .aside .aside-left .aside-title::after {
  content: '';
  width: 0.41667rem;
  height: 0.04167rem;
  background: #0499FF;
  border-radius: 0.00521rem;
  position: absolute;
  left: 0;
  bottom: -0.10417rem;
}

.app .aside .aside-left .aside-content {
  color: #0499FF;
  font-size: 0.13542rem;
  margin-bottom: 0.21354rem;
  line-height: 0.19271rem;
}

.app .aside .aside-left .aside-tips {
  color: #666;
  font-size: 0.10417rem;
  line-height: 0.20833rem;
}

.app .aside .aside-right {
  background: #9A9A9A;
}

.app .aside .aside-right .video {
  width: 100%;
}

.app .aside .aside-right .video video {
  width: 100%;
  height: 2.91667rem;
  object-fit: cover;
  border: none;
  background-color: transparent;
  outline: none;
}

.app .client {
  height: 4.67708rem;
  position: relative;
  background: #FFF;
  text-align: left;
}

.app .client .example {
  padding: 0.41667rem 1.90104rem 0 1.875rem;
}

.app .client .example .title {
  color: #333333;
  font-size: 0.17708rem;
  line-height: 0.25rem;
  margin-bottom: 0.10417rem;
}

.app .client .example .text {
  font-size: 0.10417rem;
  color: #666666;
  line-height: 0.20833rem;
}

.app .client .example-detail {
  margin-top: 0.34375rem;
}

.app .client .example-detail .row {
  padding: 0 1.875rem;
  border-bottom: 0.00521rem solid rgba(153,153,153,0.2);
  display: flex;
}

.app .client .example-detail .row .meta.head {
  font-size: 0.13542rem;
  font-weight: bold;
  padding: 0 0 0 0.13021rem;
  margin-bottom: 0.14063rem;
  position: relative;
  line-height: 0.19271rem;
}

.app .client .example-detail .row .head::before {
  content: '';
  display: inline-block;
  width: 0.04167rem;
  height: 0.16667rem;
  background: #0499FF;
  position: absolute;
  left: -0.01042rem;
  top: 50%;
  margin-top: -0.08333rem;
}

.app .client .example-detail .row .meta {
  color: #666666;
  font-size: 0.09375rem;
  width: 1.80729rem;
  padding: 0.20313rem 0;
  line-height: 0.14063rem;
  margin-right: 0.41667rem;
  z-index: 1;
}

.app .client .example-detail .row .meta span {
  color: #E9AE29;
  z-index: 1;
}

.app .client .example-detail .row .meta:last-child {
  margin-right: 0;
}

.app .client .example-detail .row:last-child {
  border-bottom: none;
}

.app .client .bg-mount {
  width: 7.56771rem;
  height: 0.79688rem;
  position: absolute;
  bottom: 0;
  left: 1.28125rem;
}

.app .partners {
  height: 2.14583rem;
  padding: 0.41667rem 1.97917rem 0;
  background: #F5F5F5;
}

.app .partners .list {
  margin-top: 0.32292rem;
  display: flex;
  margin-bottom: 0.20833rem;
}

.app .partners .list .item {
  color: #666666;
  font-size: 0.13542rem;
  line-height: 0.19271rem;
  margin-right: 0.6875rem;
  text-align: left;
}

.app .partners .list .special {
  margin-right: 1.09375rem !important;
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 4.16667rem;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

.ant-carousel .slick-slide .banner {
  position: relative;
}

.ant-carousel .slick-slide .banner .banner-image {
  width: 100%;
  height: 4.16667rem;
}

.ant-carousel .slick-slide .banner .mask-content {
  color: #fff;
  display: flex;
  position: absolute;
  left: 1.90104rem;
  bottom: 1.20313rem;
}

.ant-carousel .slick-slide .banner .mask-content .mask-title {
  text-align: left;
  margin-right: 1.28125rem;
}

.ant-carousel .slick-slide .banner .mask-content .mask-title .word-art {
  padding-bottom: 0.20833rem;
  line-height: 1.1;
}

.ant-carousel .slick-slide .banner .mask-content .mask-title .wx-art {
  color: #E9AE29;
  margin-top: 0.10417rem;
}

.ant-carousel .slick-slide .banner .mask-content .mask-title .join-btn {
  width: 1.51042rem;
  height: 0.5rem;
  line-height: 0.5rem;
  color: #E9AE29;
  font-size: 0.20833rem;
  border: 0.00521rem solid rgba(233,174,41,0.65);
  border-radius: 0.25rem;
  text-align: center;
  cursor: pointer;
  margin-top: 0.20833rem;
}

.ant-carousel .slick-slide .banner .mask-content .mask-from {
  text-align: center;
}

.ant-carousel .slick-slide .banner .mask-content .mask-from .title {
  font-size: 0.17708rem;
  line-height: 0.25rem;
}

.ant-carousel .slick-slide .banner .mask-content .mask-from input {
  width: 1.82292rem;
  height: 0.36458rem;
  font-size: 0.13542rem;
  border-radius: 0.23438rem;
  text-align: center;
  margin-top: 0.21875rem;
  border: none;
}

.ant-carousel .slick-slide .banner .mask-content .mask-from .post-btn {
  width: 1.82292rem;
  height: 0.36458rem;
  line-height: 0.36458rem;
  font-size: 0.13542rem;
  color: #e9ae29;
  border: 0.00521rem solid rgba(233,174,41,0.65);
  border-radius: 0.23438rem;
  cursor: pointer;
  margin-top: 0.20833rem;
}

.ant-carousel .slick-slide .banner .mask-content .mask-image {
  width: 1.98438rem;
}

.ant-carousel .slick-slide .banner .mask-content.second {
  bottom: 0;
}

.ant-carousel .slick-slide .banner .mask-content.second .mask-title {
  margin-right: 1.95313rem;
}