.join {
  text-align: center;
}

.join .join-header {
  color: #fff;
  width: 100%;
  height: 4.16667rem;
  position: relative;
}

.join .join-header .header-mask {
  position: absolute;
  left: 1.91146rem;
  bottom: 1.23438rem;
  text-align: left;
}

.join .join-header .header-mask .btn {
  width: 2.22917rem;
  height: 0.36458rem;
  text-align: center;
  font-size: 0.17708rem;
  line-height: 0.36458rem;
  border-radius: 0.23438rem;
  border: 0.01042rem solid rgba(255,255,255,0.65);
  margin-top: 0.20833rem;
  cursor: pointer;
}

.join .join-header img {
  height: 100%;
}

.join .section1 {
  height: 2.98438rem;
  padding: 0 1.78125rem;
  background: #F5F5F5;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.join .section1 .item {
  width: 1.75rem;
  height: 2.57292rem;
  padding: 0 0.09896rem;
  background: #fff;
}

.join .section1 .item .rectangle {
  width: 0.60417rem;
  height: 1.30208rem;
  margin: 0 auto;
  margin-top: -0.23958rem;
  margin-bottom: 0.20833rem;
}

.join .section1 .item .rectangle img {
  width: 0.41667rem;
  position: relative;
  top: 0.8125rem;
}

.join .section1 .item .title {
  font-size: 0.17708rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: 0.10417rem;
  line-height: 0.25rem;
}

.join .section1 .item .txt {
  font-size: 0.10417rem;
  text-align: left;
  color: #666666;
  line-height: 0.20833rem;
}

.join .section2 {
  height: 4.125rem;
  padding: 0.24479rem 1.80208rem 0 0.97917rem;
}

.join .section2 .title {
  font-size: 0.17708rem;
  color: #3B3B3B;
  position: relative;
  margin-bottom: 0.125rem;
}

.join .section2 .content {
  display: flex;
}

.join .section2 .content img {
  width: 4.05208rem;
  margin-right: 0.64063rem;
}

.join .section2 .content .text {
  text-align: left;
  margin-top: 0.92188rem;
}

.join .section2 .content .text li {
  font-size: 0.14583rem;
  color: #666666;
  margin-bottom: 0.20833rem;
  line-height: 0.20833rem;
}

.join .section2 .content .text li.active {
  color: #0499FF;
  position: relative;
}

.join .section2 .content .text li.active::before {
  content: '';
  width: 0.04167rem;
  height: 0.15625rem;
  position: absolute;
  background: #0499FF;
  top: 0.02604rem;
  left: -0.20833rem;
  border-radius: 0.00521rem;
}

.join .section3 {
  height: 4.25rem;
  background: #F5F5F5;
  padding: 0.44792rem 1.3125rem 0 1.875rem;
}

.join .section3 .title {
  font-size: 0.17708rem;
  color: #3B3B3B;
  position: relative;
  margin-bottom: 0.34375rem;
  line-height: 0.25rem;
}

.join .section3 .tip {
  color: #666666;
  text-align: left;
  line-height: 0.20833rem;
}

.join .section3 .content {
  color: #666666;
  display: flex;
}

.join .section3 .content img {
  width: 3.35938rem;
}

.join .section3 .content .text {
  margin-right: 0.23958rem;
  text-align: left;
}

.join .section3 .content .text .item .item-header {
  color: #333333;
  font-size: 0.13542rem;
  line-height: 0.19271rem;
  font-weight: bold;
  margin: 0.25rem 0 0.14063rem 0;
}

.join .section3 .content .text .item .item-content {
  font-size: 0.10417rem;
  color: #666666;
  line-height: 0.20833rem;
}

.underline-center::after {
  content: "";
  width: 0.41667rem;
  height: 0.04167rem;
  background: #0499FF;
  position: absolute;
  top: 0.40625rem;
  left: 50%;
  margin-left: -0.20833rem;
  border-radius: 0.00521rem;
}