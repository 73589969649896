.fade-enter {
  opacity: 0;
  z-index: 1;
  /* border: 10px red solid; */
}

.fade-enter-active {
  opacity: 0;
  transition: opacity 100ms linear;
}

.fade-exit {
  opacity: 0;
}