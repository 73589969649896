.intro {
  text-align: center;
}

.intro .intro-header {
  color: #fff;
  width: 100%;
  height: 4.16667rem;
  position: relative;
  overflow: hidden;
}

.intro .intro-header img {
  height: 100%;
}

.intro .intro-header .header-mask {
  position: absolute;
  left: 1.91146rem;
  bottom: 0.93229rem;
  text-align: left;
}

.intro .intro-header .header-mask .title {
  line-height: 0.4375rem;
}

.intro .intro-header .header-mask .info {
  font-size: 0.13542rem;
  margin-top: 0.15625rem;
  line-height: 0.19271rem;
}

.intro .intro-header .header-mask .btn {
  width: 1.81771rem;
  height: 0.36458rem;
  text-align: center;
  font-size: 0.13542rem;
  line-height: 0.36458rem;
  border-radius: 0.23438rem;
  border: 0.01042rem solid rgba(255,255,255,0.65);
  margin-top: 0.23438rem;
  margin-left: -0.01042rem;
  cursor: pointer;
}

.intro .section {
  display: flex;
  justify-content: center;
  text-align: left;
}

.intro .section .text-description .title {
  font-size: 0.17708rem;
  color: #3B3B3B;
  line-height: 0.25rem;
  position: relative;
}

.intro .section1 {
  height: 3.92708rem;
  padding: 0.29167rem 2.02083rem 0 1.32292rem;
}

.intro .section1 .picture-description {
  width: 3rem;
  height: 3.42188rem;
}

.intro .section1 .text-description {
  margin-left: 0.4375rem;
  width: 3.21354rem;
}

.intro .section1 .text-description .title {
  margin-top: 1.02604rem;
  margin-bottom: 0.44792rem;
}

.intro .section1 .text-description .content {
  font-size: 0.10417rem;
  color: #666;
  line-height: 0.20833rem;
}

.intro .section2 {
  height: 4.45313rem;
  background: #f5f5f5;
  padding: 0.27083rem 1.53646rem 0 2.02604rem;
}

.intro .section2 .picture-description {
  width: 3.5625rem;
  height: 3.85417rem;
}

.intro .section2 .text-description {
  width: 2.73958rem;
  margin-right: 0.13542rem;
}

.intro .section2 .text-description .title {
  margin-top: 0.65625rem;
  margin-bottom: 0.44792rem;
}

.intro .section2 .text-description .content {
  font-size: 0.10417rem;
  color: #666;
  line-height: 0.20833rem;
}

.intro .section3 {
  height: 3.20833rem;
  padding: 0.54688rem 1.92708rem 0 1.42188rem;
}

.intro .section3 .picture-description {
  width: 3.39583rem;
  height: 2.125rem;
}

.intro .section3 .text-description {
  width: 3.01563rem;
  margin-left: 0.23958rem;
}

.intro .section3 .text-description .title {
  margin-bottom: 0.33333rem;
  text-align: center;
}

.intro .section3 .text-description .content {
  font-size: 0.10417rem;
  color: #666;
  line-height: 0.20833rem;
}

.intro .section4 {
  height: 2.375rem;
  background: #f5f5f5;
}

.intro .section4 .content {
  width: 5.96354rem;
  height: 2.375rem;
  margin: 0 auto;
  padding: 0.44792rem 1.38021rem 0 1.46875rem;
  background-image: url("../images/introduce/bg@2x.png");
  background-size: 100% 100%;
}

.intro .section4 .content .title {
  position: relative;
  color: #3B3B3B;
  margin-bottom: 0.34375rem;
}

.intro .section4 .content .text {
  color: #666666;
  font-size: 0.10417rem;
  line-height: 0.20833rem;
}

.underline::after {
  content: "";
  width: 0.41667rem;
  height: 0.04167rem;
  background: #0499FF;
  position: absolute;
  top: 0.34375rem;
  left: 0;
  border-radius: 0.00521rem;
}

.underline-center::after {
  content: "";
  width: 0.41667rem;
  height: 0.04167rem;
  background: #0499FF;
  position: absolute;
  top: 0.34375rem;
  left: 50%;
  margin-left: -0.20833rem;
  border-radius: 0.00521rem;
}